@font-face {
  font-family: "sofia_pro_bold";
  src: url("../src/assets/fonts/sofia_pro_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

#app {
  height: 100vh;
}

.sessions {
  overflow: auto;
  display: flex;
  height: calc(100vh - 2rem);
  justify-content: center;
  padding: 1rem;
}

.mg-session {
  background: #032b6e !important;
}

.fill-screen {
  background-attachment: fixed;
}

.sessions .sessions-modal {
  display: flex;
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 1.075em 5.125em rgb(37 37 37 / 20%);
  box-shadow: 0 1.075em 5.125em rgb(37 37 37 / 20%);
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: auto;
  margin-top: auto;
  max-width: 38em;
  padding: 4em;
  /* text-align: center; */
  width: 100%;
}

.modal-space {
  padding: 2em !important;
  border-radius: 20px;
}

.centerd-content {
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

.sessions .sessions-modal-content {
  margin: 0 auto;
}

.sessions .sessions-modal-content-dis {
  margin: 0 auto;
  max-width: 22rem;
}

label,
.label {
  display: inline-block;
  position: relative;
  clear: both;
  float: none;
  width: 100%;
  color: #252525;
  font-family: "sofia_pro_bold";
  font-size: 0.875em;
  line-height: 120%;
  margin-bottom: 0.1rem;
}

a {
  color: #008bb2;
  text-decoration: underline;
}

.input-container,
.sessions .input-container,
.modal .input-container {
  margin-bottom: 2rem;
  display: inline-block;
  position: relative;
  clear: both;
  float: none;
  width: 100%;
}

.modal-actions {
  text-align: center;
}

.qb-btns {
  margin-top: 1em;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-align: center;
  align-items: center;
}

.qb-btns .cta {
  background-color: #008bb2;
}

.cta,
a.cta {
  appearance: none;
  display: inline-block;
  position: relative;
  border: 1px solid;
  font-size: 1em;
  line-height: 140%;
  padding: 1em 2em;
  -ms-flex-align: center;
  align-items: center;
  background-color: #2fb5ff;
  border-color: #2fb5ff;
  border-radius: 2em;
  color: #fff;
  cursor: pointer;
  font-family: "sofia_pro_bold";
  font-size: 0.9375em;
  letter-spacing: 0.025em;
  text-align: center;
  text-decoration: none;
}

.form-select {
  margin-top: 0.6em;
  text-align: left !important;
  min-width: 16em;
}

.select-inner-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
}

.select-inner-div input {
  border: 1px solid #a1a1a1 !important;
  border-radius: 0.125em !important;
  font-size: 14px !important;
  padding: 0.6em 6px !important;
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .select-inner-div {
    display: block;
  }
}

.select-inner-div .con-domain {
  margin: 0px 0px 2px 9px;
}

.input-error-message {
  max-width: 22rem;
  font-size: 13px;
  color: #fb5c82;
  font-family: "sofia_pro_bold";
  margin-top: 0.4em;
}

/* modal style */
.modal {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background: #fff;
  box-shadow: 0 0 3.75rem 0 rgb(0 0 0 / 10%);
  max-width: 30rem;
  padding: 10px 3.375em 3.375em;
  position: absolute;
  width: 95%;
  z-index: 10;
  border-radius: 4px;
}

.modal-close {
  transition: opacity .2s ease-in-out;
  cursor: pointer;
  display: block;
  font-family: "sofia_pro_light";
  height: 1rem;
  width: 1rem;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  z-index: 9;
}

header {
  display: inline-block;
  position: relative;
  clear: both;
  float: none;
  width: 100%;
  background-color: #fff;
  padding: 1rem 0;
}

.modal-heading {
  color: #666;
  font-family: "sofia_pro_bold";
  font-size: 1.75em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0px;
}

.modal-close .icon {
  height: 100%;
  width: 100%;
  fill: #9B9B9B;
}

.curtain {
  background-color: rgba(74,74,74,0.7);
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.modal-content {
  text-align: center;
}